import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AppRow from "../components/AppRow"
import Frame from "../components/Frame"

import Signup from "../section/Signup"

const ArayApp = () => (
    <Layout>
        <Seo title="Aray app" app={true} />
        <Signup
        // imgUrl="/pic/aray_em_ca.jpg"
        >
            <p className="signup-section__left__description_bottom">
                A female-first social network exploring the future of digital
                experiences for female golfers with the aim of creating a
                product that assembles the needs of females all over the world.
                We have questioned every detail and feature to deliver just
                that. Our app is a unique combination of great design with
                features enabling you to use golf in new ways.
            </p>
        </Signup>

        <AppRow
            title="Connect within an online community"
            description="
                Regardless if you’ve never played before, you’re a member at several clubs or non at all, age or interest, you can find and connect with others to play and enjoy your time with.
            "
            picNumArr={[1]}
        />
        <Frame frame={4} extraPadding>
            <AppRow
                title="Meet & play"
                description="
                    When in a new city, moving, new at school or wanting to increase you network; post tee-times or reach out to someone you got matched with and start growing your network of female golf friends.
                    "
                picNumArr={[2]}
                isReversed
                noMarginBottom
            />
        </Frame>
        <AppRow
            title="Learn"
            description="
                Articles, videos, podcasts, webinars or shared by you. There are many different ways you can learn about the different aspects of golf. Want to know more about your game, networking at work or on the course, work in the industry or how to be more healthy, we got you covered.
            "
            picNumArr={[7]}
        />
        <Frame frame={5} extraPadding>
            <AppRow
                title="Explore golf clubs"
                description="
                    Find the clubs and resorts near you or the once you always wanted to visit. Find out what’s going on and what they have to offer, see if anyone is playing and need another player, do you know any of their members? Directly connect and contact and be out enjoying in a second.
                    "
                picNumArr={[3, 4]}
                isReversed
                noMarginBottom
            />
        </Frame>
        <AppRow
            title="Find brands for you"
            description="
            We all have our favourite brands. Follow yours and learn from their expertise, about their products and enjoy access to exclusive offers and invites.
                    "
            picNumArr={[5, 6]}
        />
    </Layout>
)

export default ArayApp
